<template>
  <div v-if="dialogFormShow">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'576px'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="close"
    >
      <div class="shenhe-table-box">
        <el-table :data="tableData" height="250" border>
          <el-table-column
            prop="name"
            label="自测项"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                      filterable
                      v-model="tableData[scope.$index].name"
                      placeholder="请选择"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
                  prop="unit"
                  label="单位"
                  align="center"
          >
            <template slot-scope="scope">
              <el-input
                      filterable
                      v-model="tableData[scope.$index].unit"
                      placeholder="请选择"
              >
              </el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100" align="center" v-if="!readonly">
            <template slot-scope="scope">
              <el-button
                @click="handleRemoveRow(scope.row, scope.$index)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: center" v-if="!readonly">
        <el-button type="primary" size="small" :loading="posting" @click="handleAddRecords()" style="margin-right: 20px;">
          添加
        </el-button>
        <el-button type="primary" size="small" :loading="posting" @click="handleAddRecordsOk()" style="margin-left: 20px;">
          完成
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { treearr } from '@/tool'
import base from '@/templates/add'

export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      titleTable: '自测值',
      dialogFormVisible: false,
      dialogFormShow: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      formLabelWidth: '100px',
      formSelectWidth: '300px',
      formSelectWidth1: '200px',
      formSelectWidth2: '820px',
      formSelectWidth3: '1000px',
      formSelectWidth4: '170px',
      getOrgData: true,
      showAlias: false,
      tableData: [
        { unit: '', name: '' }
      ],
      factorType: '1',
      formInline: {
        page: {
          total: 0,
          size: 10,
          currentPage: 1
        }
      },
      detectionDataItem: [
        {
          detectionProjectId: '',
          detectionResult: '',
          judgeStandardId: '',
          standardValue: '',
          detectionConclusion: ''
        }
      ],
      categoryOptions: [],
      productOptions: [],
      detectionProjectEnums: [],
      detectionDeviceEnums: [],
      detectionTaskEnums: [],
      detectionTypeEnums: [],
      detectionInstitutionEnums: [],
      productionSubjectEnums: [],
      judgeStandardEnums: [],
      detectionConclusionEnums: [
        { label: '不合格', value: 0 },
        { label: '合格', value: 1 }
      ],
      fillOrgEnums: [],
      rules: {
        productCategoryId: [
          {
            required: true,
            message: '请选择样品分类',
            trigger: 'change'
          },
          {
            pattern: /\S+/,
            message: '不能全为空格'
          }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  beforeCreate () { },
  created () {
    this.loading = false

    // this.deviceInfoForm.fillPerson = this.$store.state.user.userName
    // this.deviceInfoForm.fillTime = new Date()
    // if (this.$store.state.user.areaList.length > 0) {
    //   this.deviceInfoForm.fillOrg = this.$store.state.user.areaList[0].id
    //   this.fillOrgEnums = this.$store.state.user.areaList
    // }
    // // 检测项目
    // api.detectionProject.queryAll({}).then((res) => {
    //   this.detectionProjectEnums = res.data
    // })
    // // 检测仪器
    // api.detectionDevice.queryAll({}).then((res) => {
    //   this.detectionDeviceEnums = res.data
    // })
    //
    // // 监测任务
    // api.detectiontTask.queryAll({}).then((res) => {
    //   this.detectionTaskEnums = res.data
    // })
    // // 检测类型
    // api.productionSubject.queryAll({}).then((res) => {
    //   this.productionSubjectEnums = res.data
    // })
    // // 检测类型
    // api.detectionType.queryAll({}).then((res) => {
    //   this.detectionTypeEnums = res.data
    // })
    //
    // // 检测类型
    // api.detectionInstitution.queryAll({}).then((res) => {
    //   this.detectionInstitutionEnums = res.data
    // })
    //
    // // 检测类型
    // api.judgeStandard.queryAll({}).then((res) => {
    //   this.judgeStandardEnums = res.data
    // })
  },
  beforeMount () { },
  mounted () { },
  activated () { },
  deactivated () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  methods: {
    async init () { },
    handleAddRow () {
      this.detectionDataItem.push({
        detectionProjectId: '',
        detectionResult: '',
        judgeStandardId: '',
        standardValue: '',
        detectionConclusion: ''
      })
    },
    handleClick (row) {
      console.log('row', row)

      this.dialogFormVisible = false
      this.$parent.handleAddRowOk(row)
      this.$nextTick((_) => {
        this.dialogFormShow = false
      })
    },
    handleRemoveRow (item, index) {
      this.tableData.splice(index, 1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    getTreeData () {
      api.productCategory.queryTreeData({}).then((res) => {
        this.categoryOptions = treearr(res.data)
      })
    },
    show (data) {
      this.dialogFormShow = true
      if (data) {
        console.log('emissionsSelfTestValueList', data)
        this.tableData = [...data]
      }
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    close () {
      this.tableData = []
      this.dialogFormVisible = false
      this.$nextTick((_) => {
        this.dialogFormShow = false
      })
    },
    handleAddRecordsOk () {
      this.$parent.handleAddSelfTestOk(this.tableData)
      this.tableData = []
      this.dialogFormVisible = false
      this.$nextTick((_) => {
        this.dialogFormShow = false
      })
    },
    handleAddRecords () {
      var row = {
        name: '',
        unit: ''
      }
      this.tableData.push(row)
    }
  }
}
</script>
<style lang="scss" scoped>
.shenhe-tab-box{
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
}
.shenhe-table-box {
  padding-left: 20px;
  padding-right: 20px;
}
.shenhe-form-box {
  margin-top: 15px;
}
</style>
