<template>
  <div v-if="dialogFormShow">
    <el-dialog
            :title="titleTable"
            :visible.sync="dialogFormVisible"
            :width="'720px'"
            @close="close"
    >
      <div class="shenhe-tab-box">
        <el-radio-group v-model="formInline.type" size="small" @change="toPage(1)">
          <el-radio-button :label="1" plain>平台因子库</el-radio-button>
          <el-radio-button :label="2" plain>自定义因子库</el-radio-button>
        </el-radio-group>
      </div>
      <div class="shenhe-table-box">
        <el-table :data="tableData" height="250" border v-loading="loading">
          <el-table-column
                  prop="consumeMaterial"
                  label="物料名称"
                  width="100"
                  align="center"
          >
          </el-table-column>
          <el-table-column
                  prop="consumeMaterialUnit"
                  label="计算单位"
                  width="100"
                  align="center"
          >
          </el-table-column>
          <el-table-column prop="factorLevel" label="排放因子" align="center">
          </el-table-column>
          <el-table-column prop="dataSource" label="数据来源" align="center">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100" align="center">
            <template slot-scope="scope">
              <el-button
                      @click="handleClick(scope.row)"
                      type="text"
                      size="small"
              >
                选择
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div
              class="m-page"
              :class="tableData.length <= 10 ? 'm-page2' : ''"
              data-m="dialog-list"
              v-if="formInline.page.total > 0"
      >
        <p class="u-p">{{ formInline.page.total }}条数据</p>
        <el-pagination
                layout="prev, pager, next, sizes, jumper"
                :total="formInline.page.total"
                :page-size.sync="formInline.page.size"
                :current-page="formInline.page.currentPage"
                @current-change="toPage"
                :page-sizes="[10, 20, 30, 40]"
                @size-change="toPage(1)"
        ></el-pagination>
      </div>

      <!--      <div slot="footer" class="dialog-footer">-->
      <!--        <el-button type="primary" size="small" :loading="posting" @click="handleAuditOk()">-->
      <!--          返回-->
      <!--        </el-button>-->
      <!--      </div>-->
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api'
import { treearr } from '@/tool'
import base from '@/templates/add'

export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      titleTable: '选择生产投入的原材料',
      dialogFormVisible: false,
      dialogFormShow: false,
      posting: false,
      isModelEdit: false,
      isModelAdd: false,
      formLabelWidth: '100px',
      formSelectWidth: '300px',
      formSelectWidth1: '200px',
      formSelectWidth2: '820px',
      formSelectWidth3: '1000px',
      formSelectWidth4: '170px',
      getOrgData: true,
      showAlias: false,
      tableData: [],
      formInline: {
        type: 1,
        page: {
          total: 0,
          size: 10,
          page: 1
        }
      },
      deviceInfoForm: {
        aliasName: ''
      },
      detectionDataItem: [
        {
          detectionProjectId: '',
          detectionResult: '',
          judgeStandardId: '',
          standardValue: '',
          detectionConclusion: ''
        }
      ],
      categoryOptions: [],
      productOptions: [],
      detectionProjectEnums: [],
      detectionDeviceEnums: [],
      detectionTaskEnums: [],
      detectionTypeEnums: [],
      detectionInstitutionEnums: [],
      productionSubjectEnums: [],
      judgeStandardEnums: [],
      detectionConclusionEnums: [
        { label: '不合格', value: 0 },
        { label: '合格', value: 1 }
      ],
      fillOrgEnums: [],
      rules: {
      }
    }
  },
  computed: {},
  watch: {},
  beforeCreate () { },
  created () {
    this.loading = false
  },
  beforeMount () { },
  mounted () { },
  activated () { },
  deactivated () { },
  beforeUpdate () { },
  updated () { },
  beforeDestroy () { },
  destroyed () { },
  methods: {
    async init () { },
    handleAddRow () {
      this.detectionDataItem.push({
        detectionProjectId: '',
        detectionResult: '',
        judgeStandardId: '',
        standardValue: '',
        detectionConclusion: ''
      })
    },
    handleClick (row) {
      console.log('row', row)

      this.dialogFormVisible = false
      this.$parent.handleAddRowOk(row)
      this.$nextTick((_) => {
        this.dialogFormShow = false
      })
    },
    handleRemoveRow (item, index) {
      this.detectionDataItem.splice(index, 1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    getTreeData () {
      api.productCategory.queryTreeData({}).then((res) => {
        this.categoryOptions = treearr(res.data)
      })
    },
    show () {
      this.dialogFormShow = true
      this.formInline.type = 1
      this.toPage(1)
      this.$nextTick((_) => {
        this.dialogFormVisible = true
      })
    },
    onSubmit () {
      var query = {
        page: this.formInline.page.currentPage,
        size: this.formInline.page.size,
        queryParam: {
          type: this.formInline.type
        }
      }
      this.tableData = []
      this.loading = true
      api.factor.queryPage(query).then(res => {
        this.loading = false
        this.tableData = res.data.records
        this.formInline.page.total = res.data.total
      })
    },
    close () {
      this.deviceInfoForm = {}
      this.tableData = []
      this.dialogFormVisible = false
      this.$nextTick((_) => {
        this.dialogFormShow = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .shenhe-tab-box{
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
  }
  .shenhe-table-box {
    padding-left: 20px;
    padding-right: 20px;
  }
  .shenhe-form-box {
    margin-top: 15px;
  }
</style>
